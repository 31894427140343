<template>
    <div class="homeGrid">
        <!-- 头部导航 -->
        <van-nav-bar class="navBar" fixed :placeholder="true" @click-left="$router.push('/')">
            <template #left class="left">
                <van-icon name="arrow-left" size="22" color="#141414" />
            </template>
            <template #title>
                <span class="title" @click="toSelectLearning" style="font-size: 17px; font-weight: 500">{{ learningName }}</span>
                <i class="van-icon question_back_choose"></i>
            </template>
            <template #right>
                <van-icon name="search" size="25" color="#141414" @click="toSearch" />
            </template>
        </van-nav-bar>

        <!-- 顶部banner -->
        <div class="banner">
            <van-swipe class="my_swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item,i) in bannerData" :key="i">
                    <van-image fit="cover" width="100%" height="auto" :src="item.imgPath" />
                </van-swipe-item>
            </van-swipe>
        </div>

        <!-- 题库 -->
        <div class="tiKu">
            <div class="top">
                <div class="title">题库</div>
                <div class="more" @click="goTiKu('tiku')">更多></div>
            </div>
            <div class="content">
                <div class="row1">
                    <div class="div1" @click="goTiKu('chapterExercises')">
                        <div class="title_up">章节练习（免费）</div>
                        <div class="title_down">多练习、提示能力!</div>
                    </div>
                    <div class="div2" @click="goTiKu('专项练习')">
                        <div class="title_up">专项练习（免费）</div>
                        <div class="title_down">消除薄弱新知识!</div>
                    </div>
                </div>
                <div class="row2">
                    <div class="div1" @click="goTiKu('chapter')">
                        <div class="title_up">历年真题</div>
                        <div class="title_down">知识测试</div>
                    </div>
                    <div class="div2" @click="goTiKu('simulated_paper')">
                        <div class="title_up">模拟试卷</div>
                        <div class="title_down">综合测试</div>
                    </div>
                    <div class="div3" @click="goTiKu('examination_questions')">
                        <div class="title_up">考前押题</div>
                        <div class="title_down">得分提高</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 课程 -->
        <div class="classList">
            <div class="top">
                <div class="title">课程</div>
                <div class="more" @click="goSelectClass('selectClass')">更多></div>
            </div>

            <div class="list">
                <div v-for="(item, i) in classData" :key="i">
                    <classCard v-if="item" :content="item" />
                </div>
            </div>
        </div>
        <div style="height: 10px; background: #f5f5f5"></div>

        <!-- 名师答疑 -->
        <div class="daYi">
            <div class="top">
                <div class="title">名师答疑</div>
                <div class="more" @click="gopage()">更多></div>
            </div>
            <div class="list">
                <div class="item" v-for="(item, index) in daYiData" :key="index">
                    <div class="ask">
                        <div class="icon">问答</div>
                        <div class="title_text">{{ item.questionName }}</div>
                    </div>
                    <div class="answer">
                        <span class="answer_span">答：</span> {{ item.answerContent }}
                    </div>
                </div>
            </div>
        </div>
        <div class="noMore">—— 没有更多啦～——</div>
    </div>
</template>


<script>
import classCard from "@/pages/home/component/classCard";
import { getBanner,theme1, theme9 } from "@/api/home.js";
import { getQuestionList } from "@/api/questionBank.js";
import {mapGetters} from 'vuex'

export default {
    components: { classCard },
    data() {
        return {
            firstId:137,
            learningName:'初级会计师',

            list:[], //学习方向
            bannerData:[], //banner图片
            classData: [
                {
                    courseName: "2023初级会计【超值精品班】",
                    coursePackageId: 31,
                    courseSecondName: "",
                    coverPath: "https://file.saen.com/web/K8cKYdMHTa1646193017846.png",
                    discountPrice: null,
                    isBuy: 0,
                    isDiscount: 0,
                    isFree: 1,
                    leftTime: null,
                    price: 299,
                },
            ],
            daYiData:[], //名师答疑
        }
    },
    computed:{
        ...mapGetters(['getDirection']) //{firstId:'137',learningName:'初级会计师'}
    },
    created() {
        // console.log('homeGrid-获取学习方向-----',this.$route.params.firstId)
        if(this.$route.params.firstId) this.getQuestionList() // 获取学习方向
        
    },
    methods: {
        // 获取学习方向
        getQuestionList() {
            getQuestionList().then((res) => {
                if (res.data.code === 0) {
                    this.list = res.data.data;
                    res.data.data.forEach((item,i) => {
                        if(item.id == this.$route.params.firstId){
                            this.firstId = item.id
                            this.learningName = item.name

                            this.getBanner() //获取banner图片
                            this.getClass() //获取课程列表
                            this.theme9() //获取名师答疑
                        }
                    });
                }
            })
        },
        // 选择学习方向
        toSelectLearning() {
            this.$router.push({ path: "/directionList/homeGrid" });
        },
        // 跳搜索页
        toSearch() {
            this.$router.push({ path: "/search" });
        },
        // 获取banner图片
        getBanner(){
            let formData = new FormData();
            formData.append("firstTypeId", this.firstId)
            getBanner().then(res =>{
                console.log("获取banner图片-------------", res)
                if (res.data.code == 0) {
                    this.bannerData = res.data.data;
                }
            })
        },
        // 获取课程列表
        getClass() {
            let formData = new FormData();
            formData.append("firstTypeId", this.firstId)
            theme1(formData).then((res) => {
                console.log("获取课程列表-------------", res);
                if (res.data.code == 0) {
                    this.classData = res.data.data;
                }
            });
        },
        // 获取名师答疑
        theme9(){
            theme9().then(res =>{
                console.log('获取名师答疑----',res)
                if(res.data.code == 0){
                    this.daYiData = res.data.data
                }
            })
        },

        // 点击题库
        goTiKu(target) {
            localStorage.setItem("firstId", this.getDirection.firstId);
            localStorage.setItem("learningName", this.getDirection.learningName);

            this.$router.push({
                path:'/tiku',
                query:{
                    firstId: this.getDirection.firstId || '',
                    learningName: this.getDirection.learningName || '',
                }
            })
        },
        // 点课程更多
        goSelectClass() {
            this.$router.push({ path: "/xuanke" });
        },
    },
};
</script>

<style lang="less" scoped>
.homeGrid {
    height: 100vh;
    background-color: #f5f5f5;
    // border: 1px solid blue;

    // 顶部导航
    .navBar {
        .question_back_choose {
            width: 17px;
            height: 15px;
            margin-left: 10px;
            background: url("../../assets/all_Icon/drawable-xxhdpi/question_back_choose.png")
                no-repeat 100% 100%/100%;
        }
    }

    // banner图片
    .banner {
        padding: 14px 14px 14px 14px;
        background-color: #fff;
        margin-bottom: 10px;

        .van-image {
            min-height: 136px;
        }
    }

    // 题库
    .tiKu {
        padding: 18px 14px 0 14px;
        background-color: #fff;
        margin-bottom: 10px;

        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .title {
                font-size: 17px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 550;
                color: #141414;
                line-height: 24px;
            }
            .more {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 18px;
            }
        }

        .content {
            height: 200px;
            .title_up {
                margin-left: 12px;
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 600;
                color: #24a179;
                font-family: PingFangSC-Medium, PingFang SC;
            }
            .title_down {
                margin-left: 12px;
                color: #24a179;
                font-family: PingFangSC-Medium, PingFang SC;
            }

            .row1 {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                .div1,
                .div2 {
                    width: 48%;
                    height: 77px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                .div1 {
                    background: url("../../assets/all_Icon/drawable-xxhdpi/sort_grouping_bg_b_1.png")
                        no-repeat;
                    background-size: 100% 100%;
                }
                .div2 {
                    background: url("../../assets/all_Icon/drawable-xxhdpi/sort_grouping_bg_b_2.png")
                        no-repeat;
                    background-size: 100% 100%;
                }
            }
            .row2 {
                display: flex;
                justify-content: space-between;

                .div1,
                .div2,
                .div3 {
                    width: 30.7%;
                    height: 77px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                .div1 {
                    background: url("../../assets/all_Icon/drawable-xxhdpi/sort_grouping_bg_s_1.png")
                        no-repeat;
                    background-size: 100% 100%;
                }
                .div2 {
                    background: url("../../assets/all_Icon/drawable-xxhdpi/sort_grouping_bg_s_2.png")
                        no-repeat;
                    background-size: 100% 100%;
                }
                .div3 {
                    background: url("../../assets/all_Icon/drawable-xxhdpi/sort_grouping_bg_s_3.png")
                        no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }

    // 课程
    .classList {
        padding: 18px 14px 0 14px;
        background-color: #fff;

        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .title {
                font-size: 17px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 550;
                color: #141414;
                line-height: 24px;
            }
            .more {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 18px;
            }
        }

        .list {
            min-height: 100px;
        }
    }

    // 名师答疑
    .daYi {
        padding: 18px 14px 0 14px;
        background-color: #fff;

        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 21px;
            // margin-top: 18px;
            .title {
                font-size: 17px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 550;
                color: #141414;
                line-height: 24px;
            }
            .more {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 18px;
            }
        }
        .list {
            .item {
                .ask {
                    display: flex;
                    align-items: center;
                    margin-bottom: 12px;
                    .icon {
                        background: #eaeeff;
                        border-radius: 10px 0px 10px 0px; /*no*/
                        padding: 2px 10px;
                        margin-right: 6px;
                        color: #5f7dff;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        line-height: 17px;
                    }
                    .title_text {
                        font-size: 15px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 550;
                        color: #333333;
                        line-height: 22px;
                    }
                }
                .answer {
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 18px;
                    padding: 13px 17px 12px 10px;
                    background: #f8f8f8;
                    border-radius: 5px; /*no*/
                    .answer_span {
                        font-size: 14px;
                        color: #333333;
                    }
                }
            }
        }
    }
    .noMore {
        margin: 14px 0;
        width: 100%;
        height: 100px;
        text-align: center;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 15px;
    }
}
</style>

